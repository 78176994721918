import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MODAL_IDS } from '~/constants'
import { useStellarPlaidLink } from '~/features/BankAccountManagement/Plaid/hooks/useStellarPlaidLink'
import Button from '~/components/ui/Button'
import { ADD_BANK_CLICKED } from '~/services/segment'
import { addModalToQueue } from '~/store/modules/ui'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'
import { LINK_ACCOUNT_VARIANTS } from '../../constants'

const PlaidLink = props => {
  const {
    accounts,
    variant,
    btnClassName,
    btnText,
    onClick,
    differentBankAccount,
    onLoading,
    onSuccess,
    onError,
    plaidItemUuid
  } = props

  const dispatch = useDispatch()
  const trackAddBankClicked = useSegmentEvents([ADD_BANK_CLICKED])

  const {
    openPlaid,
    disabled,
  } = useStellarPlaidLink({
    onLoading,
    onSuccess,
    onError,
    plaidItemUuid
  })

  const handleOpenPlaid = useCallback(e => {
    onClick(e)
    openPlaid(e)
    trackAddBankClicked({ provider: BANK_ACCOUNT_PROVIDERS.PLAID })
  }, [openPlaid, trackAddBankClicked, onClick])

  // in case of re-linking we need to acquaint with the latest ACH agreement template
  const handleOpenAchAgreementModal = useCallback(() => {
    dispatch(addModalToQueue({ id: MODAL_IDS.NEW_ACH_AGREEMENT_MODAL, props: { openPlaid: handleOpenPlaid } }))
  }, [dispatch, handleOpenPlaid])

  const handleButtonClick = useCallback(e => {
    if (differentBankAccount) {
      handleOpenAchAgreementModal()
    } else {
      handleOpenPlaid(e)
    }
  }, [handleOpenAchAgreementModal, handleOpenPlaid, differentBankAccount])

  return (
    <div
      data-testid="stellar-link-bank-account-plaid-link"
    >
      {variant === LINK_ACCOUNT_VARIANTS.V1 ? (
        <Button
          variant="contained"
          size=""
          className={`w-full text-base py-4 my-5 ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn"
        >
          <FontAwesomeIcon
            icon="far fa-plus"
            size="sm"
            className="mr-2 text-white"
          />
          <span
            className="text-white"
            data-testid="stellar-link-bank-account-plaid-btn-text"
          >
            {accounts.length > 0 ? 'Add another account' : 'Add checking account'}
          </span>
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V2 ? (
        <Button
          variant="contained"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v2"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V4 ? (
        <Button
          variant="outlined"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v4"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V5 ? (
        <Button
          variant="contained"
          color="green"
          size="sm"
          className={`w-full ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v5"
        >
          {btnText || 'Connect bank'}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V6 ? (
        <Button
          variant=""
          size=""
          className={`w-full text-deepspace hover:text-lightspeed underline font-bold text-base ${btnClassName}`}
          onClick={handleButtonClick}
          disabled={disabled}
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V7 ? (
        <Button
          variant=""
          size="md"
          className="bg-deepspace-50 w-full text-deepspace underline font-semibold text-lg hover:text-lightspeed p-3"
          onClick={handleButtonClick}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v7"
        >
          {btnText || 'Connect bank'}
        </Button>
      ) : null}
    </div>
  )
}
PlaidLink.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  variant: PropTypes.string,
  btnClassName: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  differentBankAccount: PropTypes.bool,
  onLoading: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func,
  plaidItemUuid: PropTypes.string,
}
PlaidLink.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  btnClassName: '',
  btnText: 'Connect bank',
  onClick: () => {},
  differentBankAccount: false,
  onLoading: () => {},
  onError: () => {},
  plaidItemUuid: '',
}

export default PlaidLink
